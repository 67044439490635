@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gabarito&family=Jost&family=Kanit&family=Lexend&family=Outfit&family=Prompt&family=Sarpanch&display=swap');

body {
  @apply bg-gray-100;
}

.text-balance {
  text-wrap: balance;
}

.bg-drip {
  background-image: url('data:image/svg+xml,<svg viewBox="0 0 960 560" width="100%" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><path d="M0 168L17.8 152C35.7 136 71.3 104 106.8 98.3C142.3 92.7 177.7 113.3 213.2 124.8C248.7 136.3 284.3 138.7 320 125C355.7 111.3 391.3 81.7 426.8 65.5C462.3 49.3 497.7 46.7 533.2 49.8C568.7 53 604.3 62 640 72.2C675.7 82.3 711.3 93.7 746.8 90.3C782.3 87 817.7 69 853.2 56.3C888.7 43.7 924.3 36.3 942.2 32.7L960 29L960 0L942.2 0C924.3 0 888.7 0 853.2 0C817.7 0 782.3 0 746.8 0C711.3 0 675.7 0 640 0C604.3 0 568.7 0 533.2 0C497.7 0 462.3 0 426.8 0C391.3 0 355.7 0 320 0C284.3 0 248.7 0 213.2 0C177.7 0 142.3 0 106.8 0C71.3 0 35.7 0 17.8 0L0 0Z" fill="%23030712" stroke-linecap="round" stroke-linejoin="miter"></path></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.footer-drip {
  background-image: url('data:image/svg+xml,<svg viewBox="0 0 900 675" width="100%" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><path d="M0 624L37.5 608.8C75 593.7 150 563.3 225 538.7C300 514 375 495 450 506.5C525 518 600 560 675 578.8C750 597.7 825 593.3 862.5 591.2L900 589L900 676L862.5 676C825 676 750 676 675 676C600 676 525 676 450 676C375 676 300 676 225 676C150 676 75 676 37.5 676L0 676Z" fill="%230c132c"></path><path d="M0 519L37.5 525.5C75 532 150 545 225 548.7C300 552.3 375 546.7 450 559.5C525 572.3 600 603.7 675 605.5C750 607.3 825 579.7 862.5 565.8L900 552L900 676L862.5 676C825 676 750 676 675 676C600 676 525 676 450 676C375 676 300 676 225 676C150 676 75 676 37.5 676L0 676Z" fill="%23090e1f"></path><path d="M0 653L37.5 647C75 641 150 629 225 619C300 609 375 601 450 606.2C525 611.3 600 629.7 675 625C750 620.3 825 592.7 862.5 578.8L900 565L900 676L862.5 676C825 676 750 676 675 676C600 676 525 676 450 676C375 676 300 676 225 676C150 676 75 676 37.5 676L0 676Z" fill="%23030712"></path></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
}

.border-glow {
  animation: borderGlow 1s ease-in-out infinite alternate;
}

@keyframes borderGlow {
  from {
    @apply border-cyan-400;
  }
  to {
    @apply border-emerald-400;
  }
}

.shadow-pulse {
  animation: shadowPulse 2s infinite ease-in-out alternate;
}

@keyframes shadowPulse {
  from {
    @apply shadow-md shadow-emerald-400;
  }
  to {
    @apply shadow-2xl shadow-emerald-400;
  }
}

.pulse-and-glow {
  animation: pulseAndGlow 2s infinite ease-in-out alternate;
}

@keyframes pulseAndGlow {
  from {
    @apply shadow-md shadow-emerald-400;
    @apply border-cyan-400;
  }
  to {
    @apply shadow-2xl shadow-emerald-400;
    @apply border-emerald-400;
  }
}
